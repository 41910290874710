import React, { Fragment } from "react"
import styled from "styled-components"

import { Banner, Section } from "../components/elements"
import {
  Grid,
  Wrapper,
  Media,
  AlignCenter,
  Color,
  Col,
  Type,
} from "../components/utilities"
import { Layout } from "../components/layouts/Layout"
const { Embed } = require("../components/utilities")

const SpecialGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;

  a,
  p {
    text-decoration: none;
    color: ${Color.internal};
    font-family: ${Type.text};
  }

  h4 {
    color: ${Color.text};
    font-family: ${Type.sub_heading};
  }

  h3 {
    font-family: ${Type.sub_heading} !important;
    font-style: italic;
    font-weight: normal !important;
    color: ${Color.text};
  }

  ${Media.below.tablet`
    text-align: center;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  `}
`

const Contact = () => (
  <Layout title="Contact Us">
    {({ data }) => (
      <Fragment>
        <Banner title="contact us" />
        <Section>
          <Wrapper>
            <SpecialGrid>
              <Col>
                <AlignCenter>
                  <h3>Contact Us</h3>
                </AlignCenter>
                <Grid modifiers={["gap"]}>
                  <Col>
                    <h4>Address:</h4>
                    <p>
                      {data.address}
                      <br />
                      {data.address_two}
                    </p>
                    <br />
                    <h4>Email:</h4>
                    <a href={`mailto:${data.email}`}>{data.email}</a>
                  </Col>
                  <Col>
                    <h4>Phone:</h4>

                    <a href={`tel:${data.phone}`}>{data.phone}</a>
                  </Col>
                </Grid>
              </Col>
              <Col>
                <Embed>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2983.5241889207227!2d-83.5699279845676!3d41.6011751792451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b87bf7ca75061%3A0x394e33207833843c!2s1209%20Dixie%20Hwy%2C%20Rossford%2C%20OH%2043460!5e0!3m2!1sen!2sus!4v1611591072547!5m2!1sen!2sus"
                    frameborder="0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </Embed>
              </Col>
            </SpecialGrid>
          </Wrapper>
        </Section>
      </Fragment>
    )}
  </Layout>
)

export default Contact
